import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/mark/di-net2/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`View Askew`}</h1>
    <h4>{`Sept. 20–Nov. 1, 2019 | Portland, ME`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px",
          "margin": "5vh 0",
          "objectFit": "contain"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "133.33333333333331%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAbABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAIBAwQF/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAIDAf/aAAwDAQACEAMQAAAB59r5Zxc0i1VLINrGBP/EABwQAAICAgMAAAAAAAAAAAAAAAABAhIRMgMQIf/aAAgBAQABBQKrRgpM5NbotnqkGeIjqM//xAAaEQABBQEAAAAAAAAAAAAAAAABAAIQERIh/9oACAEDAQE/AXk3ycBf/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAEQESH/2gAIAQIBAT8BWlz/AP/EABoQAAEFAQAAAAAAAAAAAAAAAAABEBEhMSD/2gAIAQEABj8CKdY0kszn/8QAHBAAAgMAAwEAAAAAAAAAAAAAAAERITFBUZFh/9oACAEBAAE/IUveCRLvm6P4ejzFdioStVGEtG5QaMwIojxiqT5HTEU4f//aAAwDAQACAAMAAAAQtxcC/8QAGREAAwADAAAAAAAAAAAAAAAAAAERUXGR/9oACAEDAQE/EIeAnVSGzrP/xAAXEQEBAQEAAAAAAAAAAAAAAAARAAEx/9oACAECAQE/EFiWScnb/8QAHhABAQACAgIDAAAAAAAAAAAAAREAITFBUYFhcdH/2gAIAQEAAT8QZyl83r6zacUR5POVaRlhnUaD3kTIBDFk/M1gYLxgtR7XOYC8jvNcuHwmKead4tBAnjOkZ//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "View Askew",
          "title": "View Askew",
          "src": "/static/f6273274eaa8eab1ec6d3768b70d5cc7/e5166/greenscreen_el-nk2.jpg",
          "srcSet": ["/static/f6273274eaa8eab1ec6d3768b70d5cc7/f93b5/greenscreen_el-nk2.jpg 300w", "/static/f6273274eaa8eab1ec6d3768b70d5cc7/b4294/greenscreen_el-nk2.jpg 600w", "/static/f6273274eaa8eab1ec6d3768b70d5cc7/e5166/greenscreen_el-nk2.jpg 1200w", "/static/f6273274eaa8eab1ec6d3768b70d5cc7/b768e/greenscreen_el-nk2.jpg 1350w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <p><strong parentName="p">{`For its 2019 gathering, DesignInquiry invites participants to shift viewpoints, to repeat with a twist, to view askew. This may expose what is hiding in plain sight; the splices, the seams, and the connections that bring things together but often go unnoticed. This altered view invites new perspectives, a revisioning and reimagining. We encourage methods and practices dedicated to rewriting and reviewing askant and askew. Some examples: Janet Cardiff’s sound projects layer one world over another, revealing the capacities of alternative narratives to rewrite reality. Jean Rhys rewrites Jane Eyre by telling the story of the madwoman in the attic in The Wide Sargasso Sea. Yael Kanarek is switching the pronouns in the Bible to offer a very different account of Genesis, performing a tactical alteration and an askant rereading. The Forensic Architecture group uses data to re-read and rewrite sites of human rights abuses, offering counter-readings that employ modeling and reading anew.`}</strong></p>
    <p><strong parentName="p">{`The inquiry will distribute prompts for rewriting before we begin in Maine, and rewrite while we are there, actively producing a collective collection of tactical tactics and prompting a slew of prompts to be shared and disseminated, a toolbox of activities, if you will, not for practical re-proposing but for radical reimagining.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      